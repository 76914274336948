import { Form } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../../state/store.interfaces';
import { LrvForm } from '../../../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../../../common/components/LrvText/LrvText';
import { LrvModal } from '../../../../common/components/LrvModal/LrvModal';
import { LrvSwitch } from '../../../../common/components/LrvSwitch/LrvSwitch';
import { CommercialSizeRange, ProcessedCommercialSizeRange } from '../interfaces';
import { commercialSizeTypes, weightUnitsByCompany } from '../../../../config/commons';
import { LrvDatePicker } from '../../../../common/components/LrvDatePicker/LrvDatePicker';
import { getPrices, isValidCommercialSizePriceTable } from '../../../../helpers/commercial-size-price-table';
import { generateCommercialSizeRanges, getMaxValueDisplay, MIN_VALUE_DISPLAY_GROW_OUT } from '../../../../helpers/commercial-size.helpers';

import * as tablePriceSlice from './tablePriceSlice';
import styles from './EditTablePriceForm.module.scss';
import { CommercialSizeTable } from './CommercialSizeTable';
import { CommercialSizePriceTable } from './interfaces';

interface Props {
  theme?: 'dark' | 'light';
}

export const EditTablePriceForm = (props: Props) => {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const {
    showUpdateModal,
    isUpdating,
    packer,
    commercialSize: selectedCommercialSize,
    commercialSizePriceTable,
  } = useSelector((state: Store) => state.tablePrices);

  const [active, setActive] = useState<boolean>(false);
  const [date, setDate] = useState('');
  const [commercialSizes, setCommercialSizes] = useState<number[]>([]);
  const [commercialSizeRanges, setCommercialSizeRanges] = useState<CommercialSizeRange[]>([]);
  const [commercialSizeType, setCommercialSizeType] = useState<string>('');
  const [weightUnit, setWeightUnit] = useState<string>('');
  const [processedDataSource, setProcessedDataSource] = useState<ProcessedCommercialSizeRange[]>([]);

  useEffect(() => {
    if (!selectedCommercialSize?._id || !commercialSizePriceTable?._id || !showUpdateModal) {
      return;
    }

    setActive(commercialSizePriceTable.active);
    setCommercialSizes(commercialSizePriceTable.sizes);
    setDate(commercialSizePriceTable.date);
    setCommercialSizeType(selectedCommercialSize.type);

    switch (selectedCommercialSize.type) {
      case commercialSizeTypes.GROW_OUT_WHOLE:
        setWeightUnit(weightUnitsByCompany.KILOGRAM);
        break;
    
      case commercialSizeTypes.GROW_OUT_TAIL:
        setWeightUnit(weightUnitsByCompany.POUND);
        break;
    }

    form.setFieldsValue({
      date: moment.parseZone(commercialSizePriceTable.date),
      active: commercialSizePriceTable.active,
    });

  }, [selectedCommercialSize, commercialSizePriceTable, showUpdateModal]);

  useEffect(() => {
    if (commercialSizes.length === 0) {
      return;
    }
    
    const maxValueDisplay = getMaxValueDisplay({ commercialSizeType });
    const commercialSizeRanges = generateCommercialSizeRanges({ commercialSizes, minValueDisplay: MIN_VALUE_DISPLAY_GROW_OUT, maxValueDisplay, weightUnit });
    setCommercialSizeRanges(commercialSizeRanges);
  }, [commercialSizes, weightUnit]);

  const closeModal = () => {
    form.resetFields();
    setActive(false);
    setCommercialSizes([]);
    setCommercialSizeRanges([]);
    setCommercialSizeType('');
    setWeightUnit('');
    
    dispatch(tablePriceSlice.setShowUpdateModal(false));
  };

  const updateCommercialSize = () => {
    if (!packer?._id || !selectedCommercialSize?._id || !commercialSizePriceTable?._id) {
      return;
    }

    const body: CommercialSizePriceTable = {
      date,
      active,
      sizes: commercialSizes,
      prices: getPrices({ processedDataSource }),
      packerId: packer._id,
      companyId: packer.companyId,
      commercialSizeId: selectedCommercialSize._id,
    };

    const props = {
      body,
      commercialSizePriceTableId: commercialSizePriceTable._id,
      onSuccess: closeModal,
    };

    dispatch(tablePriceSlice.updateCommercialSizePriceTable(props));
  };

  const renderSwitch = () => {
    return (
      <Form.Item>
        <div className={styles.switch}>
          <div className={styles.status}>
            <LrvText text={t('priceTable.active')} theme={theme} />
          </div>

          <LrvSwitch
            theme={theme}
            checked={active}
            onChange={(value) => setActive(value)}
          />
        </div>
      </Form.Item>
    );
  };

  return (
    <LrvModal
      theme={theme}
      title={t('priceTable.editPriceTable')}
      open={showUpdateModal}
      destroyOnClose={true}
      okButtonProps={{
        id: 'submit-commercial-size-price-table',
        htmlType: 'submit',
        form: 'form',
        loading: isUpdating,
        disabled: !isValidCommercialSizePriceTable({ processedDataSource }),
      }}
      onOk={updateCommercialSize}
      okText={t('priceTable.save')}
      cancelText={t('priceTable.cancel')}
      onCancel={closeModal}
      className={styles.editCommercialSize}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form-edit-commercial-size-price-table'
        layout='vertical'
      >
        {renderSwitch()}

        <LrvForm
          theme={theme}
          form={form}
          name='form'
          id='form-price-table'
          layout='vertical'
        >
          <Form.Item
            name='date'
            label={t('priceTable.date')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <LrvDatePicker
              theme={theme}
              placeholder={t('priceTable.date')}
              onChange={(date) => {
                const dateSelected = date?.toString() as string;
                setDate(dateSelected);
              }}
            />
          </Form.Item>
        </LrvForm>

        <Form.Item>
          <CommercialSizeTable
            commercialSizeRanges={commercialSizeRanges}
            commercialSizeType={commercialSizeType}
            processedDataSource={processedDataSource}
            setProcessedDataSource={setProcessedDataSource}
            prices={commercialSizePriceTable?.prices}
            weightUnit={weightUnit}
          />
        </Form.Item>
      </LrvForm>
    </LrvModal>
  );
};