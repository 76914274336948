import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../../state/store.interfaces';
import { LrvForm } from '../../../../common/components/LrvForm/LrvForm';
import { LrvModal } from '../../../../common/components/LrvModal/LrvModal';
import { CommercialSizeRange, ProcessedCommercialSizeRange } from '../interfaces';
import { commercialSizeTypes, weightUnitsByCompany } from '../../../../config/commons';
import { LrvDatePicker } from '../../../../common/components/LrvDatePicker/LrvDatePicker';
import { getPrices, isValidCommercialSizePriceTable } from '../../../../helpers/commercial-size-price-table';
import { generateCommercialSizeRanges, getMaxValueDisplay, MIN_VALUE_DISPLAY_GROW_OUT } from '../../../../helpers/commercial-size.helpers';

import { CommercialSizePriceTable } from './interfaces';
import * as commercialSizeSlice from './tablePriceSlice';
import { CommercialSizeTable } from './CommercialSizeTable';

interface Props {
  theme?: 'dark' | 'light';
}

export const NewTablePriceForm = (props: Props) => {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const {
    isCreating,
    showCreateModal,
    packer,
    commercialSize,
  } = useSelector((state: Store) => state.tablePrices);

  const [date, setDate] = useState('');
  const [commercialSizes, setCommercialSizes] = useState<number[]>([]);
  const [commercialSizeRanges, setCommercialSizeRanges] = useState<CommercialSizeRange[]>([]);
  const [weightUnit, setWeightUnit] = useState<string>(weightUnitsByCompany.KILOGRAM);
  const [processedDataSource, setProcessedDataSource] = useState<ProcessedCommercialSizeRange[]>([]);

  const commercialSizeType = commercialSize?.type || '';

  useEffect(() => {
    if (!commercialSize?._id || !showCreateModal) {
      return;
    }

    setCommercialSizes(commercialSize.sizes);

    switch (commercialSize.type) {
      case commercialSizeTypes.GROW_OUT_WHOLE:
        setWeightUnit(weightUnitsByCompany.KILOGRAM);
        break;
    
      case commercialSizeTypes.GROW_OUT_TAIL:
        setWeightUnit(weightUnitsByCompany.POUND);
        break;
    }
  }, [form, commercialSize, showCreateModal]);

  useEffect(() => {
    if (commercialSizes.length === 0 || !weightUnit) {
      return;
    }

    const maxValueDisplay = getMaxValueDisplay({ commercialSizeType });
    const commercialSizeRanges = generateCommercialSizeRanges({ commercialSizes, minValueDisplay: MIN_VALUE_DISPLAY_GROW_OUT, maxValueDisplay, weightUnit });
    setCommercialSizeRanges(commercialSizeRanges);
  }, [commercialSizes, weightUnit]);

  const closeModal = () => {
    form.resetFields();
    setDate('');
    setCommercialSizes([]);
    setCommercialSizeRanges([]);
    setWeightUnit('');

    dispatch(commercialSizeSlice.setShowCreateModal(false));
  };

  const addCommercialSize = () => {
    if (!packer?._id || !commercialSize?._id) {
      return;
    }

    const body: CommercialSizePriceTable = {
      date,
      active: true,
      sizes: commercialSizes,
      prices: getPrices({ processedDataSource }),
      packerId: packer._id,
      companyId: packer.companyId,
      commercialSizeId: commercialSize._id,
    };

    const props = {
      body,
      onSuccess: closeModal,
    };

    dispatch(commercialSizeSlice.createCommercialSizePriceTable(props));
  };

  return (
    <LrvModal
      theme='light'
      title={t('priceTable.newPriceTable')}
      open={showCreateModal}
      destroyOnClose={true}
      okButtonProps={{
        id: 'submit-price-table',
        htmlType: 'submit',
        form: 'form',
        loading: isCreating,
        disabled: !isValidCommercialSizePriceTable({ processedDataSource }),
      }}
      onOk={addCommercialSize}
      okText={t('priceTable.save')}
      cancelText={t('priceTable.cancel')}
      onCancel={closeModal}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form-price-table'
        layout='vertical'
      >
        <LrvForm
          theme={theme}
          form={form}
          name='form'
          id='form-price-table'
          layout='vertical'
        >
          <Form.Item
            name='date'
            label={t('priceTable.date')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <LrvDatePicker
              theme={theme}
              placeholder={t('priceTable.date')}
              onChange={(date) => {
                const dateSelected = date?.toString() as string;
                setDate(dateSelected);
              }}
            />
          </Form.Item>
        </LrvForm>

        <Form.Item>
          <CommercialSizeTable
            commercialSizeRanges={commercialSizeRanges}
            commercialSizeType={commercialSizeType}
            processedDataSource={processedDataSource}
            setProcessedDataSource={setProcessedDataSource}
            weightUnit={weightUnit}
          />
        </Form.Item>
      </LrvForm>
    </LrvModal>
  );
};