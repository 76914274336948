import i18next from 'i18next';
import { cloneDeep } from 'lodash';

import { openErrorNotification } from '../common/notification/Notification';
import { DefaultCommercialSize } from '../pages/Company/Packers/CommercialSizes/interfaces';
import { CommercialSizeRange, ProcessedCommercialSizeRange } from '../pages/Company/Packers/interfaces';
import { commercialSizeTypes, LBS_TO_GR_RATIO, roundTwoDecimals, weightUnitsByCompany } from '../config/commons';

export const MIN_VALUE_DISPLAY_GROW_OUT = 0;
const MAX_VALUE_DISPLAY_GROW_OUT_WHOLE = 160;
const MAX_VALUE_DISPLAY_GROW_OUT_TAIL = 120;

const CLICKABLE_MAX_VALUE_GROW_OUT_WHOLE = 150;
const CLICKABLE_MAX_VALUE_GROW_OUT_TAIL = 110;
const CLICKABLE_MIN_VALUE_GROW_OUT_WHOLE = 10;
const CLICKABLE_MIN_VALUE_GROW_OUT_TAIL = 7;

const EXTRA_VALUE_AVERAGE_WEGIHT = 0.01;

export const getMaxValueDisplay = (props: { commercialSizeType: string }): number => {
  const { commercialSizeType } = props;

  switch (commercialSizeType) {
    case commercialSizeTypes.GROW_OUT_WHOLE:
    default:
      return MAX_VALUE_DISPLAY_GROW_OUT_WHOLE;
  
    case commercialSizeTypes.GROW_OUT_TAIL:
      return MAX_VALUE_DISPLAY_GROW_OUT_TAIL;
  }
};

export const getClickableMinValue = (props: {commercialSizeType: string}) => {
  const { commercialSizeType } = props;

  switch (commercialSizeType) {
    case commercialSizeTypes.GROW_OUT_WHOLE:
    default:
      return CLICKABLE_MIN_VALUE_GROW_OUT_WHOLE;
  
    case commercialSizeTypes.GROW_OUT_TAIL:
      return CLICKABLE_MIN_VALUE_GROW_OUT_TAIL;
  }
};

export const getClickableMaxValue = (props: { commercialSizeType: string }): number => {
  const { commercialSizeType } = props;

  switch (commercialSizeType) {
    case commercialSizeTypes.GROW_OUT_WHOLE:
    default:
      return CLICKABLE_MAX_VALUE_GROW_OUT_WHOLE;
  
    case commercialSizeTypes.GROW_OUT_TAIL:
      return CLICKABLE_MAX_VALUE_GROW_OUT_TAIL;
  }
};

const calcAverageWeight = (props: { commercialSize: number; weightUnit: string; }) => {
  const { commercialSize, weightUnit } = props;

  if (commercialSize === 0) {
    return 0;
  }

  switch (weightUnit) {
    case weightUnitsByCompany.KILOGRAM: {
      return 1000 / commercialSize;
    }

    case weightUnitsByCompany.POUND: {
      return LBS_TO_GR_RATIO / commercialSize;
    }
  
    default:
      return 0;
  }
};

const getCommercialMinSize = (props: { commercialSize: number; weightUnit: string; }) => {
  const { commercialSize, weightUnit } = props;

  if (commercialSize === 0) {
    return 0;
  }

  switch (weightUnit) {
    case weightUnitsByCompany.KILOGRAM: {
      return commercialSize;
    }

    case weightUnitsByCompany.POUND: {
      return commercialSize + 1;
    }
  
    default:
      return 0;
  }
};

export const generateCommercialSizeRanges = (props: { commercialSizes: number[]; minValueDisplay: number; maxValueDisplay: number; weightUnit: string; }): CommercialSizeRange[] => {
  const { commercialSizes, minValueDisplay, maxValueDisplay, weightUnit } = props;

  // Sort and ensure boundary values are included
  const sortedValues = ensureBoundaryValues(commercialSizes, minValueDisplay, maxValueDisplay, weightUnit);

  // Generate weight ranges
  return sortedValues.slice(0, -1).map((value, index) => {
    const nextValue = sortedValues[index + 1];

    return {
      commercialMinSize: value,
      commercialMaxSize: nextValue,
      commercialMinSizeLabel: getCommercialMinSize({ commercialSize: value, weightUnit }),
      averageMinWeight: roundTwoDecimals(calcAverageWeight({ commercialSize: nextValue, weightUnit }) + EXTRA_VALUE_AVERAGE_WEGIHT),
      averageMaxWeight: roundTwoDecimals(calcAverageWeight({ commercialSize: value, weightUnit })),
    };
  });
};

// Helper function to sort values and ensure boundaries
const ensureBoundaryValues = (
  values: number[],
  minValue: number,
  maxValue: number,
  weightUnit: string
): number[] => {
  const sortedValues = [...values].sort((a, b) => a - b);

  if (sortedValues[0] !== minValue) {
    sortedValues.unshift(minValue);
  }

  const unitSpecificMaxValue = getMaxValueForUnit(maxValue, weightUnit);
  if (sortedValues[sortedValues.length - 1] !== unitSpecificMaxValue) {
    sortedValues.push(unitSpecificMaxValue);
  }

  return sortedValues;
};

// Helper function to get the max value based on weight unit
const getMaxValueForUnit = (defaultMaxValue: number, weightUnit: string): number => {
  return weightUnit === weightUnitsByCompany.POUND
    ? CLICKABLE_MAX_VALUE_GROW_OUT_TAIL
    : defaultMaxValue;
};

export const getDefaultCommercialSizes = (props: { commercialSizeType: string; defaultCommercialSizes: DefaultCommercialSize }) => {
  const { commercialSizeType, defaultCommercialSizes } = props;

  switch (commercialSizeType) {
    case commercialSizeTypes.GROW_OUT_WHOLE: {
      const numericLabels = cloneDeep(defaultCommercialSizes.whole.numericLabels);
      const commercialSizes = numericLabels.sort((a, b) => a - b);
      return commercialSizes;
    }
  
    case commercialSizeTypes.GROW_OUT_TAIL: {
      const numericLabels = cloneDeep(defaultCommercialSizes.tail.numericLabels);
      const commercialSizes = numericLabels.sort((a, b) => a - b);
      return commercialSizes;
    }

    default:
      return [];
  }
};

export const processCommercialSizeRanges = (props: {ranges: CommercialSizeRange[]; maxValueDisplay: number; commercialSizeType: string; clickableMinValue: number; }): ProcessedCommercialSizeRange[] => {
  const { ranges, maxValueDisplay, commercialSizeType, clickableMinValue } = props;

  switch (commercialSizeType) {
    case commercialSizeTypes.GROW_OUT_WHOLE:
    default: {
      return processCommercialSizeRangesForWhole({ ranges, maxValueDisplay, clickableMinValue });
    }
  
    case commercialSizeTypes.GROW_OUT_TAIL: {
      return processCommercialSizeRangesForTail({ ranges, maxValueDisplay });
    }
  }
};

const generateSizeLabel = (props: {record: CommercialSizeRange; clickableMinValue?: number; maxValueDisplay: number}): string => {
  const { record, clickableMinValue, maxValueDisplay } = props;
  
  if (clickableMinValue && record.commercialMinSizeLabel < clickableMinValue) {
    return `< ${record.commercialMaxSize}`;
  }
  
  if (record.commercialMaxSize === maxValueDisplay) {
    return `${record.commercialMinSizeLabel} >`;
  }

  return `${record.commercialMinSizeLabel} - ${record.commercialMaxSize}`;
};

const generateWeightRange = (record: CommercialSizeRange, maxValueDisplay: number, minValueDisplay: number): string => {
  if (record.averageMaxWeight === minValueDisplay) {
    return `${record.averageMinWeight}`;
  }
  
  if (record.commercialMaxSize === maxValueDisplay) {
    return `${record.averageMaxWeight}`;
  }

  return `${record.averageMinWeight} - ${record.averageMaxWeight}`;
};

export const processCommercialSizeRangesForWhole = (props: {ranges: CommercialSizeRange[]; maxValueDisplay: number; clickableMinValue: number; }): ProcessedCommercialSizeRange[] => {
  const { ranges, maxValueDisplay, clickableMinValue } = props;

  return ranges.map((record) => {
    const commercialSizeLabel = generateSizeLabel({ record, clickableMinValue, maxValueDisplay });
    const weightRange = generateWeightRange(record, maxValueDisplay, MIN_VALUE_DISPLAY_GROW_OUT);

    return { commercialSizeLabel, weightRange };
  });
};

export const processCommercialSizeRangesForTail = (props: {ranges: CommercialSizeRange[]; maxValueDisplay: number; }): ProcessedCommercialSizeRange[] => {
  const { ranges, maxValueDisplay } = props;
  const commercialMinSize = 15;

  return ranges.map((record) => {
    let commercialSizeLabel = '';
    if (record.commercialMaxSize <= commercialMinSize) {
      commercialSizeLabel = `U-${record.commercialMaxSize}`;
    } else {
      commercialSizeLabel = generateSizeLabel({ record, maxValueDisplay });
    }

    const weightRange = generateWeightRange(record, maxValueDisplay, MIN_VALUE_DISPLAY_GROW_OUT);

    return { commercialSizeLabel, weightRange };
  });
};

export const showErrorCommercialSize = (props: { error: string; }) => {
  const { error } = props;

  switch (error) {
    case 'commercialSizeAssociated':
      openErrorNotification(i18next.t('commercialSizes.errors.commercialSizeAssociated'));
      break;
  }
};
