import { ProcessedCommercialSizeRange } from '../pages/Company/Packers/interfaces';

export const isValidCommercialSizePriceTable = (props: {processedDataSource: ProcessedCommercialSizeRange[]}) => {
  const { processedDataSource } = props;

  return processedDataSource.every(commercialSize => !!commercialSize.price);
};

export const getPrices = (props: {processedDataSource: ProcessedCommercialSizeRange[]}): number[] => {
  const { processedDataSource } = props;

  return processedDataSource
    .map(commercialSize => commercialSize.price)
    .filter((price): price is number => price !== undefined && price !== null);
};

export const assignPricesToProcessedData = (props: {processedDataSource: ProcessedCommercialSizeRange[]; prices: number[] | undefined}): ProcessedCommercialSizeRange[] => {
  const { processedDataSource, prices } = props;

  if (!prices?.length) {
    return processedDataSource;
  }

  return processedDataSource.map((item, index) => ({
    ...item,
    price: prices[index],
  }));
};
