import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { Col, Form, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Store } from '../../../../state/store.interfaces';
import Icon from '../../../../common/components/Icon/Icon';
import { filterOptionSelect } from '../../../../utils/select';
import { CommercialSize, CommercialSizeRange } from '../interfaces';
import { LrvForm } from '../../../../common/components/LrvForm/LrvForm';
import { LrvText } from '../../../../common/components/LrvText/LrvText';
import { LrvModal } from '../../../../common/components/LrvModal/LrvModal';
import { LrvInput } from '../../../../common/components/LrvInput/LrvInput';
import { LrvSwitch } from '../../../../common/components/LrvSwitch/LrvSwitch';
import { LrvSelect } from '../../../../common/components/LrvSelect/LrvSelect';
import { openSuccessNotification } from '../../../../common/notification/Notification';
import { commercialSizeTypes, weightUnitsByCompany } from '../../../../config/commons';
import { LrvMultiRangeSlider } from '../../../../common/components/LrvMultiRangeSlider/LrvMultiRangeSlider';
import { generateCommercialSizeRanges, getMaxValueDisplay, getClickableMinValue, getClickableMaxValue, MIN_VALUE_DISPLAY_GROW_OUT } from '../../../../helpers/commercial-size.helpers';

import styles from './EditCommercialSizeForm.module.scss';
import { CommercialSizeTable } from './CommercialSizeTable';
import * as commercialSizeSlice from './commercialSizeSlice';

interface Props {
  theme?: 'dark' | 'light';
}

const { Option } = Select;

export const EditCommercialSizeForm = (props: Props) => {
  const { theme = 'dark' } = props;
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const {
    showUpdateModal,
    isUpdating,
    packer,
    commercialSize: selectedCommercialSize,
  } = useSelector((state: Store) => state.commercialSizes);

  const [active, setActive] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [commercialSizes, setCommercialSizes] = useState<number[]>([]);
  const [commercialSizeRanges, setCommercialSizeRanges] = useState<CommercialSizeRange[]>([]);
  const [commercialSizeType, setCommercialSizeType] = useState<string>('');
  const [weightUnit, setWeightUnit] = useState<string>('');

  useEffect(() => {
    if (!selectedCommercialSize?._id || !showUpdateModal) {
      return;
    }

    setName(selectedCommercialSize.name);
    setActive(selectedCommercialSize.active);
    setCommercialSizes(selectedCommercialSize.sizes);
    setCommercialSizeType(selectedCommercialSize.type);
    
    switch (selectedCommercialSize.type) {
      case commercialSizeTypes.GROW_OUT_WHOLE:
        setWeightUnit(weightUnitsByCompany.KILOGRAM);
        break;
    
      case commercialSizeTypes.GROW_OUT_TAIL:
        setWeightUnit(weightUnitsByCompany.POUND);
        break;
    }

    form.setFieldsValue({
      active: selectedCommercialSize.active,
      name: selectedCommercialSize.name,
    });
  }, [form, selectedCommercialSize, showUpdateModal]);

  useEffect(() => {
    if (commercialSizes.length === 0) {
      return;
    }
    
    const maxValueDisplay = getMaxValueDisplay({ commercialSizeType });
    const commercialSizeRanges = generateCommercialSizeRanges({ commercialSizes, minValueDisplay: MIN_VALUE_DISPLAY_GROW_OUT, maxValueDisplay, weightUnit });
    setCommercialSizeRanges(commercialSizeRanges);
  }, [commercialSizes, weightUnit]);

  const closeModal = () => {
    form.resetFields();
    setName('');
    setActive(false);
    setCommercialSizes([]);
    setCommercialSizeRanges([]);
    setCommercialSizeType('');
    setWeightUnit('');
    
    dispatch(commercialSizeSlice.setShowUpdateModal(false));
  };

  const disableSubmitButton = !name || !commercialSizeType || commercialSizeRanges.length === 0;

  const updateCommercialSize = () => {
    if (disableSubmitButton || !packer?._id || !packer.commercialSizes) {
      return;
    }

    const newCommercialSize: CommercialSize = {
      name,
      type: commercialSizeType,
      active,
      sizes: commercialSizes,
    };

    const commercialSizesCopy: CommercialSize[] = cloneDeep(packer.commercialSizes);
    const newCommercialSizes = commercialSizesCopy.map((commercialSize) =>
      commercialSize._id === selectedCommercialSize?._id ? { ...commercialSize, ...newCommercialSize } : commercialSize
    );

    const props = {
      packerId: packer._id,
      commercialSizes: newCommercialSizes,
      commercialSizeId: selectedCommercialSize?._id,
      onSuccess: () => {
        openSuccessNotification(t('commercialSizes.updated'));
        closeModal();
      },
    };

    dispatch(commercialSizeSlice.updateCommercialSize(props));
  };

  const renderCommercialSizeName = () => {
    return (
      <Form.Item
        name='name'
        label={t('commercialSizes.name')}
        rules={[{ required: true, message: t('common.requiredField') }]}
      >
        <LrvInput
          theme={theme}
          value={name}
          placeholder={t('commercialSizes.name')}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Item>
    );
  };

  const renderSwitch = () => {
    return (
      <Form.Item>
        <div className={styles.switch}>
          <div className={styles.status}>
            <LrvText text={t('commercialSizes.active')} theme={theme} />
          </div>

          <LrvSwitch
            theme={theme}
            checked={active}
            onChange={(value) => setActive(value)}
          />
        </div>
      </Form.Item>
    );
  };

  const renderCommercialSizeType = () => {
    return (
      <Form.Item
        required
        name='commercialSizeType'
        label={t('commercialSizes.type')}
        rules={[{ required: true }]}
        valuePropName='defaultValue'
        initialValue={commercialSizeType}
      >
        <LrvSelect
          theme='light'
          showSearch
          suffixIcon={<Icon name='arrow-down-s' />}
          placeholder={t('campus.phase')}
          optionFilterProp='children'
          value={commercialSizeType}
          onChange={value => {
            setCommercialSizeType(value);

            switch (value) {
              case commercialSizeTypes.GROW_OUT_WHOLE:
                setWeightUnit(weightUnitsByCompany.KILOGRAM);
                break;
            
              case commercialSizeTypes.GROW_OUT_TAIL:
                setWeightUnit(weightUnitsByCompany.POUND);
                break;
            }
          }}
          filterOption={filterOptionSelect}
          dropdownMatchSelectWidth={false}
        >
          {
            Object.keys(commercialSizeTypes).map((key) => <Option key={key} value={key}>{t(`commercialSizes.commercialSizeTypes.${key}`)}</Option>)
          }
        </LrvSelect >
      </Form.Item>
    );
  };

  return (
    <LrvModal
      theme={theme}
      title={t('commercialSizes.editCommercialSize')}
      open={showUpdateModal}
      destroyOnClose={true}
      okButtonProps={{
        id: 'submit-commercial-size',
        htmlType: 'submit',
        form: 'form',
        loading: isUpdating,
        disabled: disableSubmitButton,
      }}
      onOk={updateCommercialSize}
      okText={t('commercialSizes.save')}
      cancelText={t('commercialSizes.cancel')}
      onCancel={closeModal}
      className={styles.editCommercialSize}
      width={720}
    >
      <LrvForm
        theme={theme}
        form={form}
        name='form'
        id='form-edit-commercial-size'
        layout='vertical'
      >
        {renderSwitch()}

        <Row gutter={16}>
          <Col span={12}>
            {renderCommercialSizeName()}
          </Col>

          <Col span={12}>
            {renderCommercialSizeType()}
          </Col>

          <Col span={24}>
            <Form.Item
              label={
                <div className={styles.commercialSizesByAnimal}>
                  <div className={styles.title}>{t('commercialSizes.byAnimal', { unit: t(`commercialSizes.weightUnits.${weightUnit}`) })}</div>
                  <div className={styles.description}>{t('commercialSizes.slider.add')}</div>
                  <div className={styles.description}>{t('commercialSizes.slider.remove')}</div>
                </div>
              }
            >
              <LrvMultiRangeSlider
                initialValues={commercialSizes}
                minValueDisplay={MIN_VALUE_DISPLAY_GROW_OUT}
                maxValueDisplay={getMaxValueDisplay({ commercialSizeType })}
                clickableMinValue={getClickableMinValue({ commercialSizeType })}
                clickableMaxValue={getClickableMaxValue({ commercialSizeType })}
                onChange={(values) => setCommercialSizes(values)}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              <CommercialSizeTable
                commercialSizeRanges={commercialSizeRanges}
                commercialSizeType={commercialSizeType}
              />
            </Form.Item>
          </Col>
        </Row>
      </LrvForm>
    </LrvModal>
  );
};