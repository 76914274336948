import { useEffect } from 'react';
import { Dropdown, Menu } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { CommercialSize } from '../interfaces';
import { Store } from '../../../../state/store.interfaces';
import { commonOptions } from '../../../../config/commons';
import Icon from '../../../../common/components/Icon/Icon';
import { getCurrentTheme } from '../../../../helpers/theme';
import { LrvTag } from '../../../../common/components/LrvTag/LrvTag';
import IconButton from '../../../../common/components/buttons/IconButton';
import { LrvTable } from '../../../../common/components/LrvTable/LrvTable';
import ActionButton from '../../../../common/components/buttons/ActionButton';
import Breadcrumbs from '../../../../common/components/Breadcrumb/Breadcrumbs';
import { changeBreadcrumb, changeHeader } from '../../../AppHeader/headerSlice';

import styles from './CommercialSizes.module.scss';
import * as commercialSizesSlice from './commercialSizeSlice';
import { NewCommercialSizeForm } from './NewCommercialSizeForm';
import { EditCommercialSizeForm } from './EditCommercialSizeForm';

type TParams = { packerId: string };

export const CommercialSizes = ({ match }: RouteComponentProps<TParams>) => {
  const packerId = match.params.packerId;

  const theme = getCurrentTheme();

  const [t] = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    packer,
    isLoading,
    defaultCommercialSizes,
  } = useSelector((state: Store) => state.commercialSizes);
  const { breadcrumb } = useSelector((state: Store) => state.header);

  useEffect(() => {
    dispatch(changeHeader({ title: 'commercialSizes.title' }));
  }, [dispatch]);

  useEffect(() => {
    if (!packer.name) {
      return;
    }

    const breadcrumb = [
      { id: 'packers', name: t('packers.title'), url: '/company/packers' },
      { id: 'commercial-sizes', name: packer.name, url: `/company/packers/${packer._id}` },
    ];
    dispatch(changeBreadcrumb(breadcrumb));
  }, [dispatch, packer]);

  useEffect(() => {
    if (!packerId || packerId === packer?._id) {
      return;
    }

    dispatch(commercialSizesSlice.fetchPacker({ packerId }));
  }, [dispatch, packerId, packer?._id]);

  const menuItemsOptions = (record: CommercialSize) => {
    return (
      <Menu
        onClick={({ key, domEvent }) => {
          domEvent.stopPropagation();

          switch (key) {
            case commonOptions.EDIT:
              if (!record._id) {
                return;
              }

              dispatch(commercialSizesSlice.setShowUpdateModal(true));
              dispatch(commercialSizesSlice.setCommercialSize(record));
              return;
          }
        }}
      >
        <Menu.Item id='menu_option_edit' key={commonOptions.EDIT} className={styles.menuItemOptions}>
          <Icon name='edit' theme={theme} className={styles.icon} />
          <span>{t('stockings.edit')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  const renderStatusTag = (record: CommercialSize) => {
    if (record.active) {
      // if return null, table will display an "-" in the empty cell
      return <div></div>;
    }

    const id = `tag_${record._id}`;
    return (
      <LrvTag
        id={id}
        type='info'
        className={styles.statusTag}
      >
        {t('commercialSizes.inactive')}
      </LrvTag>
    );
  };

  const columns: ColumnsType<CommercialSize> = [
    {
      key: 1,
      title: t('commercialSizes.name'),
      width: '35%',
      dataIndex: 'name',
    },
    {
      key: 2,
      title: t('commercialSizes.type'),
      width: '35%',
      render: (_, record: CommercialSize) => {
        return t(`commercialSizes.commercialSizeTypes.${record.type}`);
      },
    },
    {
      key: 3,
      dataIndex: 'active',
      width: '30%',
      render: (_, record: CommercialSize) => {
        return renderStatusTag(record);
      },
    },
    {
      key: 4,
      width: '5%',
      render: (_, record: CommercialSize) => {
        return (
          <Dropdown
            overlay={() => menuItemsOptions(record)}
            trigger={['click']}
            placement='bottomRight'
          >
            <IconButton
              onClick={(e) => e.stopPropagation()}
              iconName='more-2'
            />
          </Dropdown>
        );
      },
    }
  ];

  return (
    <div className={styles.container}>
      <Breadcrumbs breadcrumb={breadcrumb} theme={theme} />

      <div className={styles.alignButtonRight}>
        <ActionButton
          id='add_button'
          type='primary'
          icon={<Icon name='add' />}
          onClick={() => {
            dispatch(commercialSizesSlice.setShowCreateModal(true));

            if (defaultCommercialSizes.whole.numericLabels.length === 0) {
              dispatch(commercialSizesSlice.fetchDefaultCommercialSizes());
            }
          }}
        >
          {t('stockingParameter.create')}
        </ActionButton>
      </div>

      <LrvTable
        columns={columns}
        dataSource={packer.commercialSizes}
        loading={isLoading}
        scroll={{ y: '' }}
        theme={theme}
        size='small'
        pagination={false}
        onRow={(record: CommercialSize) => {
          return {
            onClick: (e) => {
              e.stopPropagation(); // This avoids click confusion when extraAction buttons are clicked
              history.push('/company/packers/' + packer._id + '/commercial-size/' + record._id);
            },
          };
        }}
      />
      
      <NewCommercialSizeForm theme='light' />
      <EditCommercialSizeForm theme='light' />
    </div>
  );
};
