import { groupBy } from 'lodash';
import seedrandom from 'seedrandom';

import { Analysis } from '../../Analysis/interfaces';
import { convertKilogramsToPounds } from '../../../helpers/stocking.helpers';
import { getCurrentElementHeight, getCurrentElementWidth } from '../../../utils/dimensions';
import { DEFAULT_STAGE_MAX, roundOneDecimal, roundTwoDecimals, stockingPhaseTypes } from '../../../config/commons';

import { DataSource, DataSourceByStage, Point } from './interfaces';

const POWER_BASE = 5.83;
const POWER_EXPONENT = -0.407;
const DAYS_OF_THE_WEEK = 7;

export const chartParameters = {
  POC: 'POC',
  WEIGHT: 'WEIGHT',
  BIOMASS: 'BIOMASS',
  BIOMASS_KG: 'BIOMASS_KG',
  BIOMASS_LB: 'BIOMASS_LB',
  CORRECTED_FOOD: 'CORRECTED_FOOD',
  POTENTIAL_GAIN: 'POTENTIAL_GAIN',
  POTENTIAL_INCOME: 'POTENTIAL_INCOME',
  TOTAL_ACCUMULATED_COST: 'TOTAL_ACCUMULATED_COST',
};

export const FEEDING_STRATEGY = {
  HIGH: 1.05,
  NORMAL: 1,
  LOW: 0.95,
};

interface HeightProps {
  filters: React.RefObject<HTMLDivElement>;
}

interface WidthProps {
  inputsContainer?: React.RefObject<HTMLDivElement>;
}

export const getHeightOfTheOtherElements = (props: HeightProps) => {
  const { filters } = props;

  const headerHeight = 64;
  const extraHeight = 136;

  const value = getCurrentElementHeight(filters) + headerHeight + extraHeight;
  return value;
};

export const getWidthOfTheOtherElements = (props: WidthProps) => {
  const { inputsContainer } = props;
  const elementWidth = inputsContainer ? getCurrentElementWidth(inputsContainer) : 0;

  const extraWidth = 90;
  let sidebarWidth = 0;

  if (window.innerWidth > 950) {
    sidebarWidth = 80;
  }

  if (window.innerWidth > 1420) {
    sidebarWidth = 240;
  }

  return sidebarWidth + extraWidth + elementWidth;
};

export const getAverageWeight = (phaseType: string, averageWeight: number) => {
  if (phaseType === stockingPhaseTypes.LARVAE) {
    return roundTwoDecimals(averageWeight);
  }

  return roundTwoDecimals(averageWeight / 1000);
};

export const getNumberTicks = (props: { firstStage: number; lastStage: number; predictions: Analysis[]; isExcluding: boolean; }) => {
  const { firstStage, lastStage, predictions, isExcluding } = props;
  const SEVEN_DAYS = 7;
  const daysAfterLastStage = predictions.length * SEVEN_DAYS;

  if (!isExcluding) {
    const stagesDiff = (lastStage - firstStage) / 2;
    return stagesDiff < DEFAULT_STAGE_MAX ? stagesDiff : 16;
  }

  const stagesDiff = (lastStage - firstStage) - daysAfterLastStage;
  return stagesDiff < DEFAULT_STAGE_MAX ? stagesDiff : 16;
};

function randomNormal (rng: seedrandom.PRNG, weightPredicted: number, sigma: number) {
  const u1 = rng();
  const u2 = rng();
  const z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2.0 * Math.PI * u2); // Transformed from Box-Muller
  return (z0 * sigma) + weightPredicted;
}

export const getRandomWeights = (params: { dataSource: DataSource; predictionSelected: Point; size?: number }) => {
  const { dataSource, predictionSelected, size = 150 } = params;
  const { predictions } = dataSource;
  const rng = seedrandom('1');

  const prediction = predictions.find(item => item.inputData.stage === predictionSelected.x);
  
  let uniformity = predictionSelected.uniformity;
  let predictedWeight = predictionSelected.y * 1000;

  if (prediction) {
    uniformity = prediction.resultData.uniformity;
    predictedWeight = prediction.resultData.averageWeight;
  }

  const cv = 100 - uniformity;
  const sigma = (cv / 100) * predictedWeight;

  return Array.from({ length: size }, () => randomNormal(rng, predictedWeight, sigma));
};

export const classifyGrowOutSizes = (ranges: number[], individualWeights: number[]) => {
  const constantToConvert = 1000;
  const length = ranges.length + 1;
  const values: number[] = Array(length).fill(0);

  for (const weight of individualWeights) {
    const convertedWeight = weight / constantToConvert;
    let rangeIndex = -1;

    for (const value of ranges) {
      rangeIndex++;

      // eslint-disable-next-line max-depth
      if (convertedWeight < value) {
        values[rangeIndex]++;
        break;
      }

      // eslint-disable-next-line max-depth
      if (rangeIndex === ranges.length - 1 && convertedWeight > value) {
        values[rangeIndex + 1]++;
        break;
      }
    }
  }

  return values;
};

const getDataSourceByStagePoint = (params: { analysesByStage: Analysis[]; }) => {
  let { analysesByStage } = params;
  const stage = analysesByStage[0].inputData.stage;
  const isPrediction = !analysesByStage[0]?._id;

  if (analysesByStage.length > 1) {
    analysesByStage = analysesByStage.sort((a, b) => b.resultData.averageWeight - a.resultData.averageWeight);
  }

  const points: Point[] = [];
  let totalWeight = 0;
  let N = 0;

  for (const analysis of analysesByStage) {
    const { _id, code, createdAt, inputData, resultData, excludedFromPrediction } = analysis;
    const { uniformity, averageWeight } = resultData;
    const y = roundTwoDecimals(averageWeight / 1000);
    const point: Point = { _id, code, createdAt, x: inputData.stage, y, uniformity, excludedFromPrediction } as Point;
    points.push(point);
    totalWeight += excludedFromPrediction ? 0 : averageWeight;
    N += excludedFromPrediction ? 0 : 1;
  }

  // because divide any number to 0, gives an error
  const meanWeight = (N === 0) ? 0 : roundTwoDecimals((totalWeight / N) / 1000);

  const dataSourceByStagePoint: DataSourceByStage = { isPrediction, x: stage, y: meanWeight, points };
  
  return dataSourceByStagePoint;
};

interface BuildAndGetFirstPredictionParams {
  dataSourceByStage: DataSourceByStage;
  initialPopulation: number;
  survival: number;
  dailyFeeding: number;
  costPerVolumeDay: number;
  volume: number;
  salePricePerPound: number;
  accumulatedCost: number;
}

const buildAndGetFirstPrediction = (params: BuildAndGetFirstPredictionParams) => {
  const { dataSourceByStage, initialPopulation, survival, dailyFeeding, costPerVolumeDay, volume, salePricePerPound } = params;

  const biomass = Math.round((initialPopulation * dataSourceByStage.y) / 1000);
  const biomassLb = Math.round(convertKilogramsToPounds(biomass));
  const noExcludedPoints = dataSourceByStage.points?.filter(item => !item.excludedFromPrediction) as Point[];
  const uniformitySum = noExcludedPoints.reduce((sum, point) => sum + point.uniformity, 0);
  const uniformity = uniformitySum / noExcludedPoints.length;
  const createdAt = dataSourceByStage?.points ? dataSourceByStage.points[0].createdAt : '';
  const correctedFoodQuantity = dailyFeeding;
  const modelFoodQuantity = Math.round(Math.pow(POWER_BASE * dataSourceByStage.y, POWER_EXPONENT) * biomass);
  const accumulatedCost = costPerVolumeDay * dataSourceByStage.x * volume;
  const balancedAccumulatedCost = params.accumulatedCost;
  const totalAccumulatedCost = accumulatedCost + balancedAccumulatedCost;
  const potentialIncome = biomassLb * salePricePerPound;
  const potentialGain = potentialIncome - totalAccumulatedCost;

  const firstPrediction: Point = {
    x: dataSourceByStage.x, y: dataSourceByStage.y,
    createdAt,
    uniformity,
    biomass, biomassLb,
    population: initialPopulation,
    survival,
    correctedFoodQuantity, modelFoodQuantity,
    accumulatedCost,
    balancedAccumulatedCost,
    totalAccumulatedCost,
    potentialIncome, potentialGain,
  };
  return firstPrediction;
};

interface CalcDataSourceParams {
  dataSource: DataSource;
  firstStage: number;
  isExcluding: boolean;
  initialPopulation: number;
  survival: number;
  dailyFeeding: number;
  costPerVolumeDay: number;
  volume: number;
  salePricePerPound: number;
  accumulatedCost: number;
  harvestsAndTransfers: number;
  mortality: number;
  foodPricePerKg: number;
  animalsSown: number;
}

export const calcDataSource = (params: CalcDataSourceParams) => {
  const {
    dataSource, isExcluding, firstStage,
    dailyFeeding, costPerVolumeDay, initialPopulation, salePricePerPound, volume,
    harvestsAndTransfers, mortality, animalsSown, foodPricePerKg,
  } = params;

  const dataSourceByStage: DataSourceByStage[] = [];
  const allPredictions: Point[] = [];
  const allPoints: Point[] = [];

  if (dataSource.allAnalysis.length === 0 || dataSource.predictions.length === 0) {
    return { dataSourceByStage, allPredictions, allPoints };
  }

  let analysesToGroup = dataSource.allAnalysis;

  if (!isExcluding) {
    analysesToGroup = dataSource.allAnalysis.filter(item => item.inputData.stage >= firstStage);
  }

  if (analysesToGroup.length === 0) {
    return { dataSourceByStage, allPredictions, allPoints };
  }

  const analysisGroupByStage = groupBy(analysesToGroup, 'inputData.stage');
  
  for (const key in analysisGroupByStage) {
    if (Object.prototype.hasOwnProperty.call(analysisGroupByStage, key)) {
      const dataSourceByStagePoint = getDataSourceByStagePoint({ analysesByStage: analysisGroupByStage[key] });
      dataSourceByStage.push(dataSourceByStagePoint);
    }
  }

  const lastItem = dataSourceByStage[dataSourceByStage.length - 1];
  const firstPrediction = buildAndGetFirstPrediction({ accumulatedCost: params.accumulatedCost, costPerVolumeDay, dailyFeeding, dataSourceByStage: lastItem, initialPopulation, salePricePerPound, survival: params.survival, volume });
  const firstPredictionStage = firstPrediction.x;
  const firstPredictionWeight = firstPrediction.y;
  const firstPredictionUniformity = firstPrediction.uniformity;
  allPredictions.push(firstPrediction);

  const lastPrediction = dataSource.predictions[dataSource.predictions.length - 1];
  const lastPredictionWeight = roundTwoDecimals(lastPrediction.resultData.averageWeight / 1000);
  const lastPredictionUniformity = lastPrediction.resultData.uniformity;
  const lastPredictionStage = lastPrediction.inputData.stage;

  let prevPrediction = firstPrediction;
  let stage = firstPredictionStage + 1;

  while (stage < lastPredictionStage) {
    const { y: prevAverageWeight, uniformity: prevUniformity, createdAt: prevCreatedAt } = prevPrediction;
    const prevPopulation = prevPrediction.population as number;
    const prevCorrectedFoodQuantity = prevPrediction.correctedFoodQuantity as number;
    const prevModelFoodQuantity = prevPrediction.modelFoodQuantity as number;
    const prevBalancedAccumulatedCost = prevPrediction.balancedAccumulatedCost as number;
  
    const uniformity = prevUniformity + ((lastPredictionUniformity - firstPredictionUniformity) / (lastPredictionStage - firstPredictionStage));
    const averageWeight = prevAverageWeight + ((lastPredictionWeight - firstPredictionWeight) / (lastPredictionStage - firstPredictionStage));
    const population = Math.round(prevPopulation - (prevPopulation * mortality / (DAYS_OF_THE_WEEK * 100)));
    const biomass = Math.round(population * averageWeight / 1000);
    const biomassLb = Math.round(convertKilogramsToPounds(biomass));
    const survival = roundTwoDecimals((population + harvestsAndTransfers) / animalsSown * 100);
    const modelFoodQuantity = roundOneDecimal(Math.pow(POWER_BASE * averageWeight, POWER_EXPONENT) * biomass);
    const correctedFoodQuantity = roundOneDecimal(prevCorrectedFoodQuantity * modelFoodQuantity / prevModelFoodQuantity);
    const accumulatedCost = costPerVolumeDay * stage * volume;
    const balancedAccumulatedCost = prevBalancedAccumulatedCost + (correctedFoodQuantity * foodPricePerKg);
    const totalAccumulatedCost = accumulatedCost + balancedAccumulatedCost;
    const potentialIncome = biomassLb * salePricePerPound;
    const potentialGain = potentialIncome - totalAccumulatedCost;
    const createdAt = new Date(prevCreatedAt);
    createdAt.setDate(createdAt.getDate() + 1);
  
    const newPrediction = {
      isPrediction: true,
      uniformity,
      x: stage, y: averageWeight,
      population,
      biomass, biomassLb,
      survival,
      modelFoodQuantity, correctedFoodQuantity,
      accumulatedCost,
      balancedAccumulatedCost,
      totalAccumulatedCost,
      potentialIncome, potentialGain,
      createdAt: createdAt.toISOString(),
    };
    const dataSourceByStagePoint: DataSourceByStage = { isPrediction: true, x: stage, y: averageWeight, points: [newPrediction] };
    dataSourceByStage.push(dataSourceByStagePoint);
    allPredictions.push(newPrediction);
    prevPrediction = newPrediction;
    stage += 1;
  }

  const prevPopulation = prevPrediction.population as number;
  const prevCorrectedFoodQuantity = prevPrediction.correctedFoodQuantity as number;
  const prevModelFoodQuantity = prevPrediction.modelFoodQuantity as number;
  const prevBalancedAccumulatedCost = prevPrediction.balancedAccumulatedCost as number;
  const prevCreatedAt = prevPrediction.createdAt;
  
  const population = Math.round(prevPopulation - (prevPopulation * mortality / (DAYS_OF_THE_WEEK * 100)));
  const biomass = Math.round(population * lastPredictionWeight / 1000);
  const biomassLb = Math.round(convertKilogramsToPounds(biomass));
  const survival = roundTwoDecimals((population + harvestsAndTransfers) / animalsSown * 100);
  const modelFoodQuantity = roundOneDecimal(Math.pow(POWER_BASE * lastPredictionWeight, POWER_EXPONENT) * biomass);
  const correctedFoodQuantity = roundOneDecimal(prevCorrectedFoodQuantity * modelFoodQuantity / prevModelFoodQuantity);
  const accumulatedCost = costPerVolumeDay * stage * volume;
  const balancedAccumulatedCost = prevBalancedAccumulatedCost + (correctedFoodQuantity * foodPricePerKg);
  const totalAccumulatedCost = accumulatedCost + balancedAccumulatedCost;
  const potentialIncome = biomassLb * salePricePerPound;
  const potentialGain = potentialIncome - totalAccumulatedCost;
  const createdAt = new Date(prevCreatedAt);
  createdAt.setDate(createdAt.getDate() + 1);

  const newPrediction = {
    isPrediction: true,
    x: stage,
    y: lastPredictionWeight,
    uniformity: lastPredictionUniformity,
    population,
    biomass, biomassLb,
    survival,
    modelFoodQuantity, correctedFoodQuantity,
    accumulatedCost,
    balancedAccumulatedCost,
    totalAccumulatedCost,
    potentialIncome, potentialGain,
    createdAt: createdAt.toISOString(),
  };
  const dataSourceByStagePoint: DataSourceByStage = { isPrediction: true, x: stage, y: lastPredictionWeight, points: [newPrediction] };
  dataSourceByStage.push(dataSourceByStagePoint);
  allPredictions.push(newPrediction);

  for (const item of dataSourceByStage) {
    const { points } = item;
    if (!points || points.length === 0) {
      continue;
    }
    for (const point of points) {
      allPoints.push(point);
    }
  }

  return { dataSourceByStage, allPredictions, allPoints };
};
